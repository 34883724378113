import { render, staticRenderFns } from "./HomeSlider.vue?vue&type=template&id=cfea39f8&scoped=true"
import script from "./HomeSlider.vue?vue&type=script&lang=js"
export * from "./HomeSlider.vue?vue&type=script&lang=js"
import style0 from "./HomeSlider.vue?vue&type=style&index=0&id=cfea39f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfea39f8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme2DesktopLotteryWinners: require('/app/components/Theme2/Desktop/LotteryWinners.vue').default,CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default})
